import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PostList from "../components/postlist/postlist"

class Blog extends React.Component {
  render(){
    return (
      <Layout title="Blog">
        <section>
          <h1>Blog</h1>
          <>
            My blog posts aim to help guide you through solutions for web frameworks and languages.
          </>
        </section>
        <PostList />
      </Layout>
    )
  }
}

export default Blog